//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import { tableLoaderCreator } from "@/utils/newModule";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false
    },
    paginatable: {
      type: Boolean,
      required: false
    },
    configurable: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refreshSilent();
    },
    async tableLoader({
      query
    }) {
      // Fix names on marketplace changing
      if (this.$store.state.connections.marketplace.id === "wb") {
        if (query.sort === "region") {
          query.sort = "regionName";
        }
        if (query.sort === "country") {
          query.sort = "countryName";
        }
      }
      if (this.$store.state.connections.marketplace.id === "ozon") {
        if (query.sort === "regionName") {
          query.sort = "region";
        }
        if (query.sort === "countryName") {
          query.sort = "country";
        }
        if (query.sort === "oblastOkrugName") {
          delete query.sort;
          delete query.sort_dir;
        }
      }
      return await tableLoaderCreator(this.columns, this.$store.state.connections.marketplace.title, this.$store.dispatch, query, this.reportSettings, "connections/getWbSalesByRegions", "connections/getOzSalesByRegions", {}, {});
    }
  },
  computed: {
    columns() {
      return this.$store.state.connections.marketplace.title === "Wildberries" ? [{
        title: "一个国家",
        show: true,
        name: "countryName",
        type: "text",
        width: 140,
        filter: "text",
        noOverflow: true
      }, {
        title: "区",
        show: true,
        name: "oblastOkrugName",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, this.reportSettings.group !== "oblast" && {
        title: "地区",
        show: true,
        name: "regionName",
        type: "text",
        width: 140,
        filter: "text",
        noOverflow: true
      }, {
        title: "销量(件)",
        show: true,
        name: "total_count",
        type: "number",
        width: 140,
        noOverflow: true
      }, {
        title: "销售额(RUB)",
        show: true,
        name: "total_price",
        type: "money",
        width: 140,
        noOverflow: true
      }, {
        title: "销售额(件)占总销售额的%",
        show: true,
        name: "total_count_percent",
        type: "percent",
        width: 200,
        noOverflow: true
      }, {
        title: "销售额(RUB)占总销售额的百分比%",
        show: true,
        name: "total_price_percent",
        type: "percent",
        width: 200,
        noOverflow: true
      }] : [{
        title: "一个国家",
        show: true,
        name: "country",
        type: "text",
        width: 140,
        noOverflow: true
      }, {
        title: "地区",
        show: true,
        name: "region",
        type: "text",
        width: 140,
        filter: "text",
        noOverflow: true
      }, {
        title: "销量(件)",
        show: true,
        name: "total_count",
        type: "number",
        width: 140,
        noOverflow: true
      }, {
        title: "销售额(RUB)",
        show: true,
        name: "total_price",
        type: "money",
        width: 140,
        noOverflow: true
      }, {
        title: "销售额(件)占总销售额的%",
        show: true,
        name: "total_count_percent",
        type: "percent",
        width: 200,
        noOverflow: true
      }, {
        title: "销售额(RUB)占总销售额的百分比%",
        show: true,
        name: "total_price_percent",
        type: "percent",
        width: 200,
        noOverflow: true
      }];
    }
  }
};